<template>
  <figure class="rounded-xl shadow-lg hover:shadow-xl mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark">
    <div> <img :src="project.img" :alt="project.title" class="rounded-t-xl border-none" />
    </div>
    <div class="text-center p-3">
      <p class="font-general-semibold text-md text-ternary-dark dark:text-ternary-light font-semibold mb-2">
        {{ project.title }}
      </p>
      <div class="text-left font-general-medium text-sm text-ternary-dark dark:text-ternary-light break-all" lang="en">
        {{ project.content }}
      </div>
    </div>
  </figure>
</template>
<script>
export default {
  props: ["project"],
};
</script>
