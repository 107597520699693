<template>
  <section class="sm:xs-10">
    <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 mt-6 sm:gap-5">
      <ProjectSingle v-for="project in projects" :key="project.id" :project="project" />
    </div>
  </section>
</template>
<script>
import feather from "feather-icons";
import projects from "../../data/projects";
import ProjectSingle from "./ProjectSingle.vue";
export default {
  components: {
    ProjectSingle,
  },
  data: () => {
    return {
      projects,
    };
  },
  mounted() {
    feather.replace();
  },
};
</script>
<style scoped></style>
