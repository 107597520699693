<template>
  <div>
    <!-- Banner -->
    <AppBanner class="sm:mb-8" />
    <div class="container mx-auto">
      <!-- Projects -->
      <ProjectsGrid />
      <figure
        class="relative flex flex-col-reverse shadow-lg hover:shadow-xl rounded-lg bg-secondary-light dark:bg-ternary-dark mt-20 p-6 italic">
        <p class="text-lg text-ternary-dark dark:text-ternary-light">
          <img src="@/assets/images/insurance.svg" class="w-10 mr-5 inline-block" />
          <span>If you have questions or would like to receive a quote for our superior logistics service, please call our
            Customer Service at 626-334-0100. Email us at cs@eurybia.us. We thank you very much
            for giving us the opportunity to serve your needs.</span>
        </p>
      </figure>
    </div>
  </div>
</template>
<script>
import AppBanner from "@/components/shared/AppBanner";
import ProjectsGrid from "../components/projects/ProjectsGrid.vue";
export default {
  name: "Home",
  components: {
    AppBanner,
    ProjectsGrid,
  },
};
</script>