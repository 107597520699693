<template>
  <swiper :spaceBetween="30" :centeredSlides="true"
    :autoplay="{ delay: 5000, pauseOnMouseEnter: true, disableOnInteraction: false }" :pagination="{ clickable: true }"
    :navigation="true" :modules="modules" class="h-[30rem]">
    <swiper-slide>
      <section class="relative w-full h-full bg-cover bg-center"
        :style="{ backgroundImage: 'url(' + require('@/assets/images/banner_01.png') + ')' }">
        <p
          class="w-full absolute bottom-10 left-0 text-xl font-semibold sm:text-xl xl:text-3xl xl:px-10 text-center text-white">
          Eurybia Logistics Inc. is a United States based FMC licensed NVOCC for over 10 years.
        </p>
      </section>
    </swiper-slide>
    <swiper-slide>
      <section class="relative w-full h-full bg-cover bg-center"
        :style="{ backgroundImage: 'url(' + require('@/assets/images/banner_02.png') + ')' }">
        <p
          class="w-full absolute bottom-10 left-0 text-xl font-semibold sm:text-xl xl:text-3xl xl:px-10 text-center text-white">
          Our tailored services for clients is to save their shipping expenses and time.
        </p>
      </section>
    </swiper-slide>
    <swiper-slide>
      <section class="relative w-full h-full bg-cover bg-center"
        :style="{ backgroundImage: 'url(' + require('@/assets/images/banner_03.png') + ')' }">
        <p
          class="w-full absolute bottom-10 left-0 text-xl font-semibold sm:text-xl xl:text-3xl xl:px-10 text-center text-white">
          We are aiming at making our clients' logistics experience smooth and swift. We help clients solve various
          problems for ocean shipping, air freight, customs clearance, FCL, FTL, LTL trucking, and warehousing.
        </p>
      </section>
    </swiper-slide>
    <swiper-slide>
      <section class="relative w-full h-full bg-cover bg-center"
        :style="{ backgroundImage: 'url(' + require('@/assets/images/banner_04.png') + ')' }">
        <p
          class="w-full absolute bottom-10 left-0 text-xl font-semibold sm:text-xl xl:text-3xl xl:px-10 text-center text-white">
          Trust Eurybia Logistics supply chain service to suit your business shipping needs.
        </p>
      </section>
    </swiper-slide>
  </swiper>
</template>
<script>
import feather from "feather-icons";
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper';

export default {
  name: "Home",
  components: {
    Swiper,
    SwiperSlide,
  },
  data: () => {
    return {
      theme: "",
    };
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, Navigation],
    };
  },
  created() {
    this.theme = localStorage.getItem("theme") || "light";
  },
  mounted() {
    feather.replace();
    this.theme = localStorage.getItem("theme") || "light";
  },
  updated() {
    feather.replace();
  },
  methods: {},
};
</script>
