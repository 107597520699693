<script>
export default {
	props: ['title'],
	data: () => {
		return {
		};
	},
};
</script>

<template>
	<button>{{ title }}</button>
</template>

<style scoped>
button:disabled {
	opacity: 0.75;
	cursor: not-allowed;
}
</style>
