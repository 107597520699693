<template>
  <div class="flex justify-center items-center text-center">
    <div class="
        font-general-regular
        text-lg text-ternary-dark
        dark:text-ternary-light
      ">
      &copy; {{ copyrightDate }}
      <span class="font-general-medium hover:underline hover:text-red-600 dark:hover:text-red-300 duration-500">
        {{ projectName }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  data: () => {
    return {
      copyrightDate: new Date().getFullYear(),
      projectName: "Eurybia Logistics, Inc.",
      author: "Eurybia",
    };
  },
};
</script>