// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
  {
    id: 1,
    title: " Why Choose Us?",
    content:
      "Professional team with 10+ years hands-on operation experience with extensive vendor network of industry experts provides our clients person-to-person reliable service. Our cloud-based system facilitates clients real time shipping data and easy tracking and query mechanism for CY/CY, CY/DR, warehouse and FCL, LCL transit.",
    img: require("@/assets/images/choose-us.jpg"),
  },
  {
    id: 2,
    title: " Our Expertise",
    content:
      "When you add our services to your supply chain, you’re doing more than using technology to address your challenges. Our customer-oriented logistics services involves examining processes, discovering their strengths and weaknesses, and then using that knowledge to create opportunities and success.",
    img: require("@/assets/images/our-expertise.jpg"),
  },
  {
    id: 3,
    title: " Our Goal: Delivery",
    content:
      "Eurybia Logistics, Inc. has the experience, dedication and tenacity to ensure your shipping needs are met. Please contact us today to receive a free quote and customized logistics plan to best suit your business needs.",
    img: require("@/assets/images/our-goal.jpg"),
  },
  {
    id: 4,
    title: " Trust Our Experience",
    content:
      "Eurybia Logistics, Inc. has the experience, dedication and tenacity to ensure your shipping needs are met. Please contact us today to receive a free quote and customized logistics plan to best suit your business needs.",
    img: require("@/assets/images/trust-us.jpg"),
  },
];

export default projects;
