<template>
  <transition name="fade">
    <div v-show="modal" class="font-general-regular fixed inset-0 z-30">
      <!-- Modal body background as backdrop -->
      <div v-show="modal" @click="showModal()" class="bg-filter bg-black bg-opacity-50 fixed inset-0 w-full h-full z-20">
      </div>
      <!-- Modal content -->
      <main class="flex flex-col items-center justify-center h-full w-full">
        <transition name="fade-up-down">
          <div v-show="modal" class="modal-wrapper flex items-center z-30">
            <div
              class="modal max-w-md md:max-w-xl bg-secondary-light dark:bg-primary-dark max-h-screen shadow-lg flex-row rounded-lg relative">
              <div
                class="modal-header flex justify-between gap-10 p-4 border-b border-ternary-lightdark:border-ternary-dark">
                <h5 class="text-primary-dark dark:text-primary-light text-xl">
                  Eurybia Logistics Tracking
                </h5>
                <button class="px-4 text-primary-dark dark:text-primary-light" @click="showModal()">
                  <i data-feather="x"></i>
                </button>
              </div>
              <div class="modal-body p-1 lg:p-3 w-full h-full">
                <ul
                  class="flex list-none flex-row flex-wrap border-b-0 pl-0 font-general-semibold text-primary-dark dark:text-primary-light">
                  <li role="presentation">
                    <a @click="activeTab = 'tab1'"
                      class="px-1 lg:px-7 pt-4 pb-3.5 text-xs font-bold uppercase leading-tight border-x-0 border-t-0 border-b-2 border-transparent hover:isolate hover:border-transparent hover:bg-neutral-100 dark:hover:bg-gray-800 cursor-pointer"
                      :class="{ 'border-b-red-500': activeTab == 'tab1' }">Warehouse Cargo</a>
                  </li>
                  <li role="presentation">
                    <a @click="activeTab = 'tab2'"
                      class="px-1 lg:px-7 pt-4 pb-3.5 text-xs font-bold uppercase leading-tight border-x-0 border-t-0 border-b-2 border-transparent hover:isolate hover:border-transparent hover:bg-neutral-100 dark:hover:bg-gray-800 cursor-pointer"
                      :class="{ 'border-b-red-500': activeTab == 'tab2' }">Ocean Cargo</a>
                  </li>
                  <li role="presentation">
                    <a @click="activeTab = 'tab3'"
                      class="px-1 lg:px-7 pt-4 pb-3.5 text-xs font-bold uppercase leading-tight border-x-0 border-t-0 border-b-2 border-transparent hover:isolate hover:border-transparent hover:bg-neutral-100 dark:hover:bg-gray-800 cursor-pointer"
                      :class="{ 'border-b-red-500': activeTab == 'tab3' }">Air/LCL Freight</a>
                  </li>
                </ul>
                <div class="mt-6">
                  <div :class="{ hidden: activeTab != 'tab1' }" role="tabpanel">
                    <div class="max-w-xl text-left flex gap-2">
                      <div class="w-2/3">
                        <FormInput placeholder="Enter PRO No." inputIdentifier="name" @update:val="setWhseNumber"
                          value="" />
                      </div>
                      <div class="w-1/3">
                        <div class="pb-4 sm:pb-1">
                          <Button title="Tracking"
                            class="px-4 sm:px-6 py-2 sm:py-2.5 text-white bg-red-500 hover:bg-red-600 rounded-md focus:ring-1 focus:ring-red-900 duration-500 font-semibold"
                            aria-label="Tracking" :disabled="btnDisabled(whse)" @click="track(whse, 'whse')" />
                        </div>
                      </div>
                    </div>
                    <div class="tracking-result" v-if="whse.result">
                      <ol class="border-l-2 mt-5 ml-5 border-gray-300 text-left">
                        <li v-for="(step, index) in whse.result.steps" :key="index">
                          <div class="flex items-center pt-2">
                            <div class="bg-green-600 w-5 h-5 rounded-full -ml-3 mr-3" :class="{
                              'bg-green-600': step.date,
                              'bg-red-600': !step.date
                            }"></div>
                            <p class="text-gray-800 dark:text-primary-light text-sm font-semibold">
                              {{ step.action }}
                            </p>
                          </div>
                          <div class="mt-0.5 ml-4 mb-4">
                            <p class="text-gray-800 dark:text-primary-light text-sm ml-1">
                              {{ step.name }}
                            </p>
                            <h4 class="text-red-600 font-semibold text-xl mb-1.5" v-if="step.eta">{{ step.eta }} (ETA)
                            </h4>
                            <h4 class="text-gray-800 dark:text-primary-light font-semibold text-xl mb-1.5" v-else>
                              {{ step.date }}
                            </h4>
                          </div>
                        </li>
                      </ol>
                    </div>
                    <div class="text-red-500 text-md font-semibold">{{ whse.errors }}</div>
                  </div>
                  <div :class="{ hidden: activeTab != 'tab2' }" role="tabpanel">
                    <div class="max-w-xl text-left flex gap-2">
                      <div class="w-2/3">
                        <FormInput placeholder="Enter Container No./B/L No." inputIdentifier="name" value=""
                          @update:val="setOceanNumber" />
                      </div>
                      <div class="w-1/3">
                        <div class="pb-4 sm:pb-1">
                          <Button title="Tracking"
                            class="px-4 sm:px-6 py-2 sm:py-2.5 text-white bg-red-500 hover:bg-red-600 rounded-md focus:ring-1 focus:ring-red-900 duration-500 font-semibold"
                            aria-label="Tracking" :disabled="btnDisabled(ocean)" @click="track(ocean, 'ocean')" />
                        </div>
                      </div>
                    </div>
                    <div class="tracking-result" v-if="ocean.result">
                      <section class="container px-0 mx-auto">
                        <div class="flex flex-col">
                          <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                              <div class="overflow-hidden">
                                <div class="flex items-start">
                                  <ul
                                    class="mr-4 flex list-none flex-col flex-wrap pl-0 font-general-semibold text-primary-dark dark:text-primary-light">
                                    <slot v-for="(container, index) in ocean.result">
                                      <li class="flex-grow text-center" :key="index">
                                        <a @click="ocean.activeTab = index"
                                          class="my-1 block border-2 border-transparent p-2 text-xs font-semibold cursor-pointer"
                                          :class="{ 'border-b-red-500': ocean.activeTab == index }">
                                          {{ container.number }}
                                        </a>
                                      </li>
                                    </slot>
                                  </ul>
                                  <div class="my-2 flex-grow progress-steps">
                                    <slot v-for="(container, index) in ocean.result">
                                      <div :class="{ hidden: ocean.activeTab != index }" :key="index">
                                        <div class="flex justify-between mx-6 dark:text-primary-light">
                                          <div>{{ container.type }}</div>
                                          <div>B/L No.: {{ container.hbl_bl_no }}
                                          </div>
                                        </div>
                                        <ol class="border-l-2 mt-5 ml-5 border-gray-300 text-left">
                                          <li v-for="(step, index) in container.steps" :key="index">
                                            <div class="flex items-center pt-2">
                                              <div class="bg-green-600 w-5 h-5 rounded-full -ml-3 mr-3" :class="{
                                                'bg-green-600': step.date,
                                                'bg-red-600': !step.date
                                              }"></div>
                                              <p class="text-gray-800 dark:text-primary-light text-sm font-semibold">
                                                {{ step.action }}
                                              </p>
                                            </div>
                                            <div class="mt-0.5 ml-4 mb-4">
                                              <p class="text-gray-800 dark:text-primary-light text-sm ml-1">
                                                {{ step.name }}
                                              </p>
                                              <h4
                                                class="text-gray-800 dark:text-primary-light font-semibold text-xl mb-1.5">
                                                {{ step.date }}
                                              </h4>
                                            </div>
                                          </li>
                                        </ol>
                                      </div>
                                    </slot>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div class="text-red-500 text-md font-semibold">{{ ocean.errors }}</div>
                  </div>
                  <div :class="{ hidden: activeTab != 'tab3' }" role="tabpanel">
                    <div class="max-w-xl text-left flex gap-2">
                      <div class="w-2/3">
                        <FormInput placeholder="Enter Air/LCL B/L No." inputIdentifier="name"
                          @update:val="setAirNumber" />
                      </div>
                      <div class="w-1/3">
                        <div class="pb-4 sm:pb-1">
                          <Button title="Tracking"
                            class="px-4 sm:px-6 py-2 sm:py-2.5 text-white bg-red-500 hover:bg-red-600 rounded-md focus:ring-1 focus:ring-red-900 duration-500 font-semibold"
                            aria-label="Tracking" :disabled="btnDisabled(air)" @click="track(air, 'air')" />
                        </div>
                      </div>
                    </div>
                    <div class="tracking-result" v-if="air.result">
                      <section class="container px-0 mx-auto">
                        <div class="flex flex-col">
                          <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                              <div class="overflow-hidden">
                                <div class="flex justify-between mx-6 dark:text-primary-light">
                                  <div>B/L No.: {{ air.result.hbl_bl_no }}</div>
                                </div>
                                <ol class="border-l-2 mt-5 ml-5 border-gray-300 text-left">
                                  <li v-for="(step, index) in air.result.steps" :key="index">
                                    <div class="flex items-center pt-2">
                                      <div class="bg-green-600 w-5 h-5 rounded-full -ml-3 mr-3" :class="{
                                        'bg-green-600': step.date,
                                        'bg-red-600': !step.date
                                      }"></div>
                                      <p class="text-gray-800 dark:text-primary-light text-sm font-semibold">
                                        {{ step.action }}
                                      </p>
                                    </div>
                                    <div class="mt-0.5 ml-4 mb-4">
                                      <p class="text-gray-800 dark:text-primary-light text-sm ml-1">
                                        {{ step.name }}
                                      </p>
                                      <h4 class="text-gray-800 dark:text-primary-light font-semibold text-xl mb-1.5">
                                        {{ step.date }}
                                      </h4>
                                    </div>
                                  </li>
                                </ol>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div class="text-red-500 text-md font-semibold">{{ air.errors }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </main>
    </div>
  </transition>
</template>
<script>
import feather from "feather-icons";
import Button from "./reusable/Button.vue";
import FormInput from "./reusable/FormInput.vue";
export default {
  props: ["showModal", "modal", "categories"],
  components: { Button, FormInput },
  data() {
    return {
      activeTab: 'tab1',
      whse: {
        number: "",
        errors: null,
        result: null,
      },
      ocean: {
        number: "",
        errors: null,
        result: null,
        activeTab: 0
      },
      air: {
        number: "",
        errors: null,
        result: null,
        activeTab: 0
      }
    };
  },
  mounted() {
    feather.replace();
  },
  updated() {
    feather.replace();
  },
  computed: {
    btnDisabled() {
      return function (target) {
        return target.number.length == 0;
      }
    }
  },
  methods: {
    setWhseNumber(val) {
      this.whse.number = (val || "").trim();
    },
    setOceanNumber(val) {
      this.ocean.number = (val || "").trim();
    },
    setAirNumber(val) {
      this.air.number = (val || "").trim();
    },
    async track(target, type) {
      try {
        target.result = null;
        target.errors = null;
        const url = `${process.env.VUE_APP_API_URL}/api/web/tracks?type=${type}&number=${target.number}`;
        const response = await fetch(url, { headers: { 'Accept': 'application/json' } });
        if (response.status == 200) target.result = await response.json();
        if (response.status == 422) target.errors = (await response.json()).errors;
      } catch (ex) {
        console.log(ex)
      }
    }
  },
};
</script>
<style scoped>
.modal-body {
  max-height: 90vh;
}

.tracking-result {
  max-height: calc(90vh - 180px);
  overflow: auto;
}

.progress-steps {
  max-height: calc(90vh - 200px);
  overflow: auto;
}

.bg-gray-800-opacity {
  background-color: #2d374850;
}

.fade-up-down-enter-active {
  transition: all 0.3s ease;
}

.fade-up-down-leave-active {
  transition: all 0.3s ease;
}

.fade-up-down-enter {
  transform: translateY(10%);
  opacity: 0;
}

.fade-up-down-leave-to {
  transform: translateY(10%);
  opacity: 0;
}

.fade-enter-active {
  -webkit-transition: opacity 2s;
  transition: opacity 0.3s;
}

.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
