<script>
export default {
  props: ["showModal", "isOpen"],
};
</script>

<template>
  <!-- Header links -->
  <div :class="isOpen ? 'block' : 'hidden'" class="m-0 sm:ml-4 mt-5 sm:mt-3 sm:flex p-5 sm:p-0 justify-center items-center shadow-lg sm:shadow-none
                    ">
    <router-link to="/" class="font-general-medium link " aria-label="Home">Home</router-link>
    <router-link to="/about" class="font-general-medium link" aria-label="About">About</router-link>
    <router-link to="/services" class="font-general-medium link" aria-label="Services">Services</router-link>
    <router-link to="/news" class="font-general-medium link" aria-label="News">News</router-link>
    <router-link to="/contact" class="font-general-medium link" aria-label="Contact">Contact</router-link>
    <div class="border-t-2 pt-3 sm:pt-0 sm:border-t-0 border-primary-light dark:border-secondary-dark "> <button
        class="font-general-medium sm:hidden block text-left text-md font-medium bg-red-500 hover:bg-red-600 text-white shadow-sm rounded-sm px-4 py-2 mt-2 duration-500 "
        @click="showModal()" aria-label="Tracking Button"> Tracking </button>
    </div>
  </div>
</template>

<style scoped>
.link {
  @layer font-general-medium;
  @apply block text-left text-lg font-medium text-primary-dark dark:text-ternary-light hover:text-red-600 dark:hover:text-red-300 sm:mx-4 mb-2 sm:py-2;
}
</style>
