<script>
import feather from "feather-icons";
import FooterCopyright from "./FooterCopyright.vue";
import { socialLinks } from "../../data/socialLinks";

export default {
  components: { FooterCopyright },
  data() {
    return {
      socials: socialLinks,
    };
  },
  mounted() {
    feather.replace();
  },
  updated() {
    feather.replace();
  },
};
</script>

<template>
  <div class="text-left mt-40 relative">
    <div id="air-viewport">
      <img src="@/assets/images/airplane.svg" class="h-5 -mt-12 xl:h-10 xl:-mt-24" />
    </div>
    <div id="truck1-viewport">
      <img src="@/assets/images/truck-small.svg" class="h-5 -mt-2 xl:h-10 xl:-mt-4" />
    </div>
    <div id="truck2-viewport">
      <img src="@/assets/images/truck-big.svg" class="h-5 -mt-3 xl:h-10 xl:-mt-6" />
    </div>
    <div id="ship-viewport">
      <img src="@/assets/images/ship.svg" class="h-8 -mt-8 xl:h-16 xl:-mt-16" />
    </div>
    <div class="pt-10 sm:pt-10 pb-8 mt-10 border-dashed border-t-2 border-gray-400 dark:border-primary-light">
      <!-- Footer social links -->
      <div class=" flex flex-col justify-center items-center mb-12 sm:mb-20" v-if="false">
        <p
          class=" font-general-semibold text-3xl sm:text-4xl font-semibold text-primary-dark dark:text-primary-light mb-5">
          Follow me </p>
        <ul class="flex gap-4 sm:gap-8">
          <a v-for="social in socials" :key="social.id" :href="social.url" target="__blank"
            class=" text-gray-400 hover:text-red-500 dark:hover:text-red-400 cursor-pointer rounded-lg bg-gray-50 dark:bg-ternary-dark hover:bg-gray-100 shadow-sm p-4 duration-500 ">
            <i :data-feather="social.icon" class="w-5 sm:w-8 h-5 sm:h-8" />
          </a>
        </ul>
      </div>
      <!-- Footer copyright -->
      <FooterCopyright class="mt-10" />
    </div>
  </div>
</template>

<style scoped>
#air-viewport {
  position: absolute;
  left: 0;
  top: 0;
  animation: 5s ease-in-out infinite airViewport;
}


#truck1-viewport {
  position: absolute;
  left: 0;
  top: 0;
  animation: 10s ease-in-out infinite trucker1Viewport;
}

#truck2-viewport {
  position: absolute;
  left: 0;
  top: 0;
  animation: 10s ease-in-out infinite trucker2Viewport;
}

#ship-viewport {
  position: absolute;
  left: 0;
  top: 0;
  animation: 15s ease-in-out infinite shipViewport;
}

@keyframes airViewport {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(100vw + 100%));
  }
}

@keyframes trucker1Viewport {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(100vw + 100%));
  }
}

@keyframes trucker2Viewport {
  from {
    transform: translateX(calc(100vw));
  }

  to {
    transform: translateX(calc(-100%));
  }
}

@keyframes shipViewport {
  from {
    transform: translateX(calc(100vw));
  }

  to {
    transform: translateX(-100%);
  }
}
</style>
